const huntings_detail = [
  {
    title: "PUNJAB URIAL",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "The Punjab Urial can be found in Pakistan only. The average horn length of Punjab Urial is 24 – 30 inches (60 - 76 cm). The biggest trophies registered by the SCI Record book 32 inches for Punjab specie (right horn, scored 135 points)",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description:
          "Altitude: 600 - 800 meters at this time of the year temperatures in the area will be 10 to 30°C. Therefore, the clients need to be well equipped with their outfits. A good quality sleeping bag is recommended.",
      },
      {
        heading: "Departure Dates:",
        description:
          "The hunts can take place any time from December to March.",
      },
    ],
    duration_d_n: "7 Days",
    duration_d: "7 Days",
    tour_type: "__",
    group_size: "__",
    tour_guide: "05 People",
    cover_image: "/images/hunting/punjab_urial/image_1.jpg",
    other_images: [],
    availability: "December to March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrival to Islamabad and shift to hotel",
        description: [],
      },
      {
        arrow: "Two",
        title: "Drive to hunting area (3 hours)",
        description: [],
      },
      {
        arrow: "Three",
        title: "Hunting area",
        description: [],
      },
      {
        arrow: "Four",
        title: "Hunting area",
        description: [],
      },
      {
        arrow: "Five",
        title: "Hunting area",
        description: [],
      },
      {
        arrow: "Six",
        title: "Hunting area",
        description: [],
      },
      {
        arrow: "Seven",
        title: "Drive to Islamabad",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "SINDH IBEX",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Capra Aegagrus, Subspecie/Sindh Ibex.Mature males are spectacularly beautiful, with long sweeping scimitar shaped horns over 102cm (40”) in length and almost silver white bodies offset by a sooty gray chest, throat and face. The Sindh Ibex can survive almost at sea level and in fact do so in some of the remoter cliffs around Ormara. They inhabit mountain crests up to 3,350m in the Koh-i-Maran Range. Wild goats are found in all the higher and more extensive mountain ranges of southern Baluchistan from the Makran coastal range at Pasni right across to Sind Kohistan and the Kirthar Range in the east.",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description: "Altitude: 200 – 1800 meters. Temperature 5 to 10°C",
      },
      {
        heading: "Departure Dates:",
        description:
          "The hunts can take place any time from December to March.",
      },
      {
        heading:
          "HUNTING SCHEDULE FOR SIND IBEX AND BLANDFORD IN DUREJI AND OPEN AREA",
        description: "",
      },
    ],
    duration_d_n: "6 Days",
    duration_d: "6 Days",
    tour_type: "__",
    group_size: "__",
    tour_guide: "05 People",
    cover_image: "/images/hunting/sindh_ibex/image_1.jpg",
    other_images: [],
    availability: "December to March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrival to Islamabad transfer to hotel",
        description: [],
      },
      {
        arrow: "Two",
        title: "Fly to Karachi transfer to Hunting area 3 hours Drive",
        description: [],
      },
      {
        arrow: "Three",
        title: "Looking for good ibex 38 inch or over",
        description: [],
      },
      {
        arrow: "Four",
        title: "Looking for good ibex 38 inch or over",
        description: [],
      },
      {
        arrow: "Five",
        title: "Looking for good ibex 38 inch or over",
        description: [],
      },
      {
        arrow: "Six",
        title: "Fly back",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "BLUE SHEEP",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Pakistan is one of the unique countries rich in history, culture and nature. Every region of this country has its own peculiar qualities and places of interest: from fascinating ancient ruins to beautiful national parks. For the hunters Pakistan is unique due to the great variety of mountain game animals. Pakistan Mountains are also inhabited by the Blue Sheep.",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description:
          "Altitude: 3900 - 4500 meters At this time of the year temperatures in the area will be -10 to 10°C. Therefore, the clients need to be well equipped with their outfits. A good quality sleeping bag is recommended.",
      },
      {
        heading: "Departure Dates:",
        description: "The hunt can take place any time from October or March",
      },
    ],
    duration_d_n: "14 Days",
    duration_d: "14 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/blue_sheep/image_1.jpg",
    other_images: [],
    availability: "October or March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrival at Islamabad. Transfer to hotel.",
        description: [],
      },
      {
        arrow: "Two",
        title:
          "Fly to Gilgit. In case of hight cancellation drive to Chilas. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Three",
        title: "Drive to Shimshal or KVO Village. Overnight camping.",
        description: [],
      },
      {
        arrow: "Four",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Five",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Six",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Seven",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Eight",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Nine",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Ten",
        title: "HUNTING",
        description: [],
      },
      {
        arrow: "Eleven",
        title: "Drive to Gilgit. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Twell",
        title:
          "Fly to Islamabad. In case of hight cancellation drive to Besham. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Thirteen",
        title: "Islamabad or drive from Besham to Islamabad.",
        description: [],
      },
      {
        arrow: "Forteen",
        title: "Fly to onward destination.",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "BLANDFORD URIAL",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Blandford Urial (Ovis VigneiBlandfordi) is found in Baluchistan and Sindh provinces of Pakistan. They generally inhabit lower slopes but may occur as high as9000.ft. if there is vegetation present in the form of Juniper thickets (in Baluchistan & Sindh)",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description: "Altitude: 200 – 1800 meters. Temperature 5 to 10°C",
      },
      {
        heading: "Departure Dates:",
        description:
          "The hunts can take place any time from December to March.",
      },
      {
        heading:
          "HUNTING SCHEDULE FOR SIND IBEX AND BLANDFORD IN DUREJI AND OPEN AREA",
        description: "",
      },
    ],
    duration_d_n: "6 Days",
    duration_d: "6 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/blandord_Urial/image_1.jpg",
    other_images: [],
    availability: "December to March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrival to Islamabad transfer to hotel",
        description: [],
      },
      {
        arrow: "Two",
        title: "Fly to Karachi transfer to Hunting area 3 hours Drive",
        description: [],
      },
      {
        arrow: "Three",
        title: "Looking for good urial 26 inch or over",
        description: [],
      },
      {
        arrow: "Four",
        title: "Looking for good urial 26 inch or over",
        description: [],
      },
      {
        arrow: "Five",
        title: "Looking for good urial 26 inch or over",
        description: [],
      },
      {
        arrow: "Six",
        title: "Fly back",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "HIMALAYAN IBEX",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "The Himalayan Ibex live in one of the remotest corners of the world, the mountain ranges of the Karakorum's, western Himalayas and the Hindukush. They have been a source of subsistence for the inhabitants of these remote valleys since the early time. It is generally agreed that a bull with over 40 inches of horn length is a good trophy and that anything over 44 or 45 inches is excellent. Average size of sheep is 38-40 inches with occasional 42 inches trophy taken. The Ibex can be up to 46 inches in horn length. It is an unforgettable adventure to hunt at 12,000 feet on a horseback, surrounded by a beautiful alpine panorama. We strive to make your hunt safe and successful.",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description:
          "Altitude: 3000 - 3500 meters (10,000 – 11,000 ft.) At this time of the year temperatures in the area will be 0 to -15°C. Therefore, the clients need to be well equipped with their outfits. A good quality sleeping bag is recommended.",
      },
      {
        heading: "Departure Dates:",
        description:
          "The hunts can take place any time from December to March.",
      },
    ],
    duration_d_n: "10 Days",
    duration_d: "10 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/himalayan_ibex/image_1.jpg",
    other_images: [],
    availability: "December to March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "ARRIVAL TO ISLAMABAD AND SHIFT TO HOTEL OF YOUR CHOICE",
        description: [],
      },
      {
        arrow: "Two",
        title: "DRIVE TO  GILGIT",
        description: [],
      },
      {
        arrow: "Three",
        title: "ACCLIMATIZE IN GILGIT",
        description: [],
      },
      {
        arrow: "Four",
        title: "DRIVE TO HUNTING AREA MINIMUM 3 HOURS",
        description: [],
      },
      {
        arrow: "Five",
        title: "HUNTING AREA LOOKING FOR TROPHY MINIMUM 38 inch)",
        description: [],
      },
      {
        arrow: "Six",
        title: "HUNTING AREA LOOKING FOR TROPHY MINIMUM 38 inch)",
        description: [],
      },
      {
        arrow: "Seven",
        title: "HUNTING AREA LOOKING FOR TROPHY MINIMUM 38 inch)",
        description: [],
      },
      {
        arrow: "Eight",
        title: "HUNTING AREA LOOKING FOR TROPHY MINIMUM 38 inch)",
        description: [],
      },
      {
        arrow: "Nine",
        title: "DRIVE TO  GILGIT",
        description: [],
      },
      {
        arrow: "Ten",
        title: "DRIVE TO ISLAMABAD ARRIVAL AT ISLAMABAD",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "ASTOR MARKHOR HUNT",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Astor Markhor is found on the valley slopes of the greater Karakoram Mountain range. These Markhor live in lower altitudes than Himalayan Ibex that also occur in this mountain range. The hunt will take place at 8000 to 9500 feet or 2500 - 3000 meters. Temperatures: -10 to -15°C with possibility of light rain and even snow. Please note the northern areas receive very little precipitation. However chances of rain in these months exist. Therefore clients will need warm woolen and down jackets.",
      },
      {
        heading: "TROPHY SIZES",
        description:
          'Expected average 34" to 36" and in some cases up to 40 and 44',
      },
      {
        heading: "HUNTING DATES",
        description: "The hunt can take place any time from December to March.",
      },
    ],
    duration_d_n: "12 Days",
    duration_d: "12 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/astor_markhor/image_1.jpg",
    other_images: [],
    availability: "December to March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrive Islamabad, transfer to hotel. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Two",
        title:
          "Fly to Gilgit and drive to hunt area. 1 and a half hour drive. (In case of flight cancellation) due to bad weather the hunting party will drive to Chilas. Driving time 10 hours. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Three",
        title:
          "Hunt Area or drive from Chilas to Gilgit or hunting area. 6 hours drive. Overnight camping.",
        description: [],
      },
      {
        arrow: "Four",
        title: "Hunting Looking For Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Five",
        title: "Hunting Looking For Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Six",
        title: "Hunting Looking For Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Seven",
        title: "Hunting Looking For Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Eight",
        title: "Hunting Looking For Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Nine",
        title: "Hunting Looking For Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Ten",
        title: "Drive to Gilgit. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Eleven",
        title: "Flight or drive to Islamabad.",
        description: [],
      },
      {
        arrow: "Twell",
        title: "Fly home.",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "KASHMIR MARKHOR HUNT",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Pir Punjal or Kashmir Markhor are found in the mountainous regions of, Kaigah, Gol, Chitral, Tushi. as in winters, these animals come down to the meadows near the villages where they can be seen and hunted easily. You may rest assured, with all the support from our team, we won't let you or your guests face any problems going all the way up to such places because there are no technical Altitudes of these areas start from about 2100 meters (or 7000 feet) and go up to 4500 meters (15000 feet). However, most of these areas are easily accessible by 4WD Jeeps and we have to walk for small distances, or high altitude climbs involved and we reckon them as easy to moderate walks. Hunting in some areas is so easy that hunters have shot their games while sitting in their jeeps",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description:
          "The hunt will take place at 8000 to 9500 feet or 2500 - 3000 meters. Temperatures: 10 to -10°C with possibility of light rain and even snow. Please note the northern areas receive very little precipitation. However chances of rain in these months exist. Therefore clients will need warm woolen and down jackets.",
      },
      {
        heading: "TROPHY SIZES",
        description:
          'Expected average 34" to 36" and in some cases up to 40 and 44"',
      },
      {
        heading: "HUNTING DATES",
        description: "The hunt can take place any time from December to March.",
      },
    ],
    duration_d_n: "--",
    duration_d: "9 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/kashmir_markhor/image_1.jpg",
    other_images: [],
    availability: "December to March",
    pickup: "Islamabad Airport",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrive Islamabad, transfer to hotel. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Two",
        title: "Drive or Fly to Chitral or Kaigah to overnight camping.",
        description: [],
      },
      {
        arrow: "Three",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Four",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Five",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Six",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Seven",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Eight",
        title: "Drive To Islamabad.",
        description: [],
      },
      {
        arrow: "Nine",
        title: "Fly home.",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "SULIEMAN MARKHORE HUNT",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Sulieman Markhor are found in the mountainous regions of ,Baluchistan. as in winters, these animals come down to the meadows near the villages where they can be seen and hunted easily. You may rest assured, with all the support from our team, we won't let you or your guests face any problems going all the way up to such places because there are no technical Altitudes of these areas start from about 2100 meters (or 7000 feet) and go up to 4500 meters (15000 feet). However, most of these areas are easily accessible by 4WD Jeeps and we have to walk for small distances, or high altitude climbs involved and we reckon them as easy to moderate walks. Hunting in some areas is so easy that hunters have shot their games while sitting in their jeeps",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description:
          "The hunt will take place at 8000 to 9500 feet or 2500 - 3000 meters. Temperatures: -10 to -15°C with possibility of light rain and even snow. Please note the northern areas receive very little precipitation. However chances of rain in these months exist. Therefore clients will need warm woolen and down jackets.",
      },
      {
        heading: "TROPHY SIZES",
        description:
          'Expected average 34" to 36" and in some cases up to 40 and 44”',
      },
      {
        heading: "HUNTING DATES",
        description:
          "The hunt can take place any time from 15 December till 30th March.",
      },
    ],
    duration_d_n: "10 Days",
    duration_d: "10 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/sulimen_markhor/markhor.jpg",
    other_images: [],
    availability: "15 December till 30th March",
    pickup: "Islamabad Airport",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrive Islamabad, transfer to hotel. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Two",
        title:
          "Fly to Quetta 5 Drive to hunting area Overnight at hunting Camp.",
        description: [],
      },
      {
        arrow: "Three",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Four",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Five",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Six",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Seven",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Eight",
        title: "Drive Quetta. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Nine",
        title: "Fly To Islamabad. Islamabad sightseeing.",
        description: [],
      },
      {
        arrow: "Ten",
        title: "Fly home.",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "AFHGAN URIAL",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Afghan Urial are found in the mountainous regions of ,Baluchistan. as in winters, these animals come down to the meadows near the villages where they can be seen and hunted easily. You may rest assured, with all the support from our team, we won't let you or your guests face any problems going all the way up to such places because there are no technical Altitudes of these areas start from about 2100 meters (or 7000 feet) and go up to 4500 meters (15000 feet). However, most of these areas are easily accessible by 4WD Jeeps and we have to walk for small distances, or high altitude climbs involved and we reckon them as easy to moderate walks. Hunting in some areas is so easy that hunters have shot their games while sitting in their jeeps",
      },
      {
        heading: "Altitude of Hunting Area & Temperature",
        description:
          "The hunt will take place at 8000 to 9500 feet or 2500 - 3000 meters. Temperatures: 10 to -15°C with possibility of light rain and even snow. Please note the northern areas receive very little precipitation. However chances of rain in these months exist. Therefore clients will need warm woolen and down jackets.",
      },
      {
        heading: "TROPHY SIZES",
        description:
          'Expected average 24” to 26" and in some cases up to 28” and 30',
      },
      {
        heading: "HUNTING DATES",
        description:
          "The hunt can take place any time from 15 December till 30th March.",
      },
    ],
    duration_d_n: "8 Days",
    duration_d: "8 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/afghan_urial/image_1.jpg",
    other_images: [],
    availability: "15 December till 30th March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [
      {
        arrow: "One",
        title: "Arrive Islamabad, transfer to hotel. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Two",
        title:
          "Fly to Quetta 5 Drive to hunting area Overnight at hunting Camp.",
        description: [],
      },
      {
        arrow: "Three",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Four",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Five",
        title: "Hunting Looking for Trophy Size Animal",
        description: [],
      },
      {
        arrow: "Six",
        title: "Drive Quetta. Overnight at hotel.",
        description: [],
      },
      {
        arrow: "Seven",
        title: "Fly To Islamabad. Islamabad sightseeing.",
        description: [],
      },
      {
        arrow: "Eight",
        title: "Fly home.",
        description: [],
      },
    ],
    gallery: true,
    map_track: "",
  },
  {
    title: "Wild Boar Driven Hunt",
    sub_title: "Hunting",
    details: [
      {
        heading: "Hunting Details",
        description:
          "Pakistan has many interesting animals that can be hunted only there, but the most plentiful is doubtlessly the wild boar. Since it is a Muslim country, with an extensive agriculture, numbers of wildboars are so high as to be considered a true national plague and there are no limits as to its hunting, regarding numbers or hunting seasons. The hunt is conducted in alluvial forests in the plains of Punjab, by waiting or stalking for groups of up to 3 hunters or in driven hunts with all the necessary staff and dogs for groups of 8 hunters and over. Accommodation is in small hotels or bungalows with services, and in some places in tents for 2-3 persons.",
      },
      {
        heading: "HUNTING SEASON: ",
        description: "15thNovember – 30thMarch",
      },
    ],
    duration_d_n: "5 Days",
    duration_d: "5 Days",
    tour_type: "____",
    group_size: "____",
    tour_guide: "05 People",
    cover_image: "/images/hunting/boar/boar_hunt.jpeg",
    other_images: [],
    availability: "15 December till 30th March",
    travel_by: { vehicle: "Travel With Bus", icon: "/images/icons/bus.svg" },
    itinerary: [],
    gallery: true,
    map_track: "",
  },
];
export default huntings_detail;
