import React from "react";

const huntings = [
    {
        title: 'PUNJAB URIAL',
        availability: 'December to March',
        date: '7 Days',
        price: '',
        gap: '67.5px',
        to: 1,
        image: '/images/hunting/punjab_urial/image_1.jpg',
        category: '',
        status: 'popular',
        active: true
    },
    {   //temporary
        title: 'SINDH IBEX',
        availability: 'December to March',
        date: '6 Days',
        price: '',
        gap: '67.5px',
        to: 2,
        image: '/images/hunting/sindh_ibex/image_1.jpg',
        category: '',
        status: 'popular',
        active: true
    },
    {
        title: 'BLUE SHEEP',
        availability: 'October or March',
        date: '14 Days',
        price: '',
        gap: '0px',
        to: 3,
        image: '/images/hunting/blue_sheep/image_1.jpg',
        category: '',
        status: 'popular',
        active: true
    },
    {   //temporary
        title: 'BLANDFORD URIAL',
        availability: 'December to March',
        date: '6 Days',
        price: '',
        gap: '0px',
        to: 4,
        image: '/images/hunting/blandord_Urial/image_1.jpg',
        category: '',
        status: 'popular',
        active: true
    },
    {
        title: 'HIMALAYAN IBEX',
        availability: 'December to March',
        date: '10 Days',
        price: '',
        gap: '0px',
        to: 5,
        image: '/images/hunting/himalayan_ibex/image_1.jpg',
        category: '',
        status: 'popular',
        active: true
    },
    {
        title: 'ASTOR MARKHOR HUNT',
        availability: 'December to March',
        date: '12 Days',
        price: '',
        gap: '0px',
        to: 6,
        image: '/images/hunting/astor_markhor/image_1.jpg',
        category: '',
        status: 'popular',
        active: true

    },
    {
        title: 'KASHMIR MARKHOR HUNT',
        availability: 'December to March',
        date: '9 Days',
        price: '',
        gap: '0px',
        to: 7,
        image: '/images/hunting/kashmir_markhor/image_1.jpg',
        category: '',
        status: 'popular',
        active: true

    },
    {
        title: 'SULIEMAN MARKHORE HUNT',
        availability: '15 December till 30th March',
        date: '10 Day',
        price: '',
        to: 8,
        gap: '26px',
        image: '/images/hunting/sulimen_markhor/markhor.jpg',
        category: 'cultural',
        status: 'popular',
        active: true
    },
    {
        title: "AFHGAN URIAL",
        availability: '15 December till 30th March',
        date: "8 Days",
        price: "",
        to: 9,
        gap: '0px',
        image: "/images/hunting/afghan_urial/image_1.jpg",
        category: '',
        status: 'popular',
        active: true
    },
    {
        title: "Wild Boar Driven Hunt",
        availability: '15 December till 30th March',
        date: "5 Days",
        price: "",
        to: 10,
        gap: '0px',
        image: "/images/hunting/boar/boar_hunt.jpeg",
        category: '',
        status: 'popular',
        active: true
    }
]

export default huntings